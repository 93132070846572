// Your variable overrides
// $body-bg: #000;
// $body-color: #111;

// Корпоративные цвета.
$brand-strong-color: #5f8a96;
$brand-orange-color: #ff6600;

$primary: $brand-orange-color;
$font-size-base: 0.9rem; // default: 1rem = 16px;
$navbar-brand-font-size: 1rem;

$border-radius: .1rem; // default: .25rem;
$border-radius-lg: .15rem; // default: .3rem;
$border-radius-sm: .05rem; // default: .2rem;

$enable-validation-icons: false;

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";
@import "sibfu";
@import "select2-theme/layout";

body {
    min-height: 75rem;
    padding-top: 4.5rem;
}

nav {
    .navbar-brand {
        line-height: $navbar-brand-font-size * 2;
    }
}
