.s2-togall-select .s2-unselect-label,
.s2-togall-unselect .s2-select-label {
    display: none;
}

.s2-togall-select .s2-select-label,
.s2-togall-unselect .s2-unselect-label {
    display: inline;
}

.s2-select-label,
.s2-unselect-label {
    line-height: 1.1;
    font-size: 13px;
}

.s2-togall-button {
    display: inline-block;
    font-weight: 400;
    color: $primary;
    padding: 0.5rem;
    cursor: pointer;

    &:focus,
    &:hover {
        color: $primary;
        text-decoration: underline;
        background-color: transparent;
    }

    .s2-select-label,
    .s2-unselect-label {
        svg,
        i {
            margin: auto 0.5rem;
        }
    }
}
