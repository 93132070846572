.select2-selection--multiple {
  background-color: $input-bg;
  border: $input-border-width solid $input-border-color;
  cursor: text;

  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);

  .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;

    li {
      list-style: none;
    }
  }

  .select2-selection__placeholder {
    color: $input-placeholder-color;
    margin-top: 8px;
    float: left;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 8px;
    margin-right: 8px;
  }

  .select2-selection__choice {
    background-color: $input-disabled-bg;

    border: 1px solid $input-disabled-bg;
    border-radius: 4px;
    cursor: default;

    float: left;

    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
  }

  .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;

    display: inline-block;
    font-weight: bold;

    margin-right: 2px;

    &:hover {
      color: #333;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

&.select2-container--focus {
  .select2-selection--multiple {
    // @include form-control-focus();
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
    box-shadow: $input-focus-box-shadow;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: $input-disabled-bg;
    cursor: default;
  }

  .select2-selection__choice__remove {
    display: none;
  }
}
