table.table.detail-view {
    th {
        vertical-align: middle;
    }
}

.sibfu {
    &-page-title {
        @extend #{
            '.d-flex',
            '.justify-content-between',
            '.flex-wrap',
            '.flex-md-nowrap',
            '.align-items-center',
            '.pt-3',
            '.pb-1',
            '.mb-3',
            '.border-bottom'
        };
    }

    &-page-title-toolbar {
        @extend #{
            '.mb-2',
            // '.mb-md-1'
        };

        > .btn-group {
            @extend #{'.mr-2'};
        }
    }

    &-breadcrumbs {
        position: relative;
        overflow-y: hidden;

        > .breadcrumb {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;

            > li.breadcrumb-item:last-of-type {
                padding-right: $breadcrumb-padding-x;
            }
        }
    }


    &-table {
        caption {
            color:#666;
            font-size: 14px;
            font-style: italic;
            line-height: 20px;
            text-align: left;
            vertical-align:top;
            padding:0 0 .5em 0;
            margin:.3em 0 2px 0;
            border-bottom: 5px solid $brand-orange-color;
            caption-side: top;
        }

        th {
            background-color: $brand-strong-color;
            border-bottom: 0 !important;

            &,
            a {
                color: white;
            }
        }

        .table-sm {
            font-size: 13px;
        }
    }
}
